import useScript from "./hooks/useScript";
import Landing from "./landing";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SearchResult from "./search-result";
import Admin from "./admin";
import InformationHotel from "./admin/modules/information-hotel";
import Situation from "./admin/modules/sitation";
import GestionInstallationsEtEspaces from "./admin/modules/gestion-installations-espaces";
import GestionSallesConferences from "./admin/modules/gestion-salles-conferences";
import GestionGastronomie from "./admin/modules/gestion-gastronomie";
import GestionAnimationLoisir from "./admin/modules/animation-loisir";
import Marche from "./admin/modules/marche";
import ConditionsChambres from "./admin/modules/conditions-chambres";
import Conditions from "./admin/modules/conditions-chambres/conditions";
import MinStay from "./admin/modules/conditions-chambres/min-stay";
import Stock from "./admin/modules/conditions-chambres/stock";
import StopSale from "./admin/modules/conditions-chambres/stop-sale";
import PrixChambre from "./admin/modules/conditions-chambres/prix-chambre";
import ReleaseDate from "./admin/modules/conditions-chambres/release-date";
import { default as HebergementsAdmin } from "./admin/modules/hebergements";
import Reservations from "./admin/modules/reservations";
import Promo from "./admin/modules/conditions-chambres/promo";
import List from "./admin/modules/conditions-chambres/promo/list";
import CreatePromo from "./admin/modules/conditions-chambres/promo/create";
import Langues from "./admin/modules/langues";
import { useEffect } from "react";
import BaseService from "./service/base.service";
import { useDispatch } from "react-redux";
import { setLanguages } from "./app/slice/client.slice";
import Chambres from "./client/chambres";
import Gastro from "./client/gastro";
import SalleConference from "./client/salle-conference";
import Spas from "./client/spas";
import SalleFetes from "./client/salle-fetes";
import Acceuil from "./client/acceuil";
import GestionSpa from "./admin/modules/spas";
import MediaSpas from "./admin/modules/spas/media";
import ListePrixSpas from "./admin/modules/spas/liste-prix";
import Login from "./admin/modules/Auth/Login";
import GestionUsers from "./admin/modules/Auth/GestionUsers";


const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Landing />,
    children: [
      {
        path: "/",
        element: <Acceuil />,
      },
      {
        path: "rooms",
        element: <Chambres />,
      },
      {
        path: "gastros",
        element: <Gastro />,
      },
      {
        path: "conferences",
        element: <SalleConference />,
      },
      {
        path: "spas",
        element: <Spas />,
      },
      {
        path: "parties",
        element: <SalleFetes />,
      },
    ],
  },
  // {
  //   path: "reservation",
  //   element: <OpenInNewTab />,
  // },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin",
        element: <p>Bienvenue</p>
      },
      {
        path: "information-hotel",
        element: <InformationHotel />,
      },
      {
        path: "situation",
        element: <Situation />,
      },
      {
        path: "hebergements",
        element: <HebergementsAdmin />,
      },
      {
        path: "installations-espaces",
        element: <GestionInstallationsEtEspaces />,
      },
      {
        path: "salles-conferences",
        element: <GestionSallesConferences />,
      },
      {
        path: "gastronomie",
        element: <GestionGastronomie />,
      },
      {
        path: "spas",
        element: <GestionSpa />,
        children: [
          {
            path: "media",
            element: <MediaSpas />,
          },
          {
            path: "liste-prix",
            element: <ListePrixSpas />,
          },
        ],
      },
      {
        path: "animation-loisir",
        element: <GestionAnimationLoisir />,
      },
      {
        path: "marches",
        element: <Marche />,
      },
      {
        path: "reservations",
        element: <Reservations />,
      },
      {
        path: "conditions-chambres",
        element: <ConditionsChambres />,
        children: [
          {
            path: "conditions",
            element: <Conditions />,
          },
          {
            path: "release-date",
            element: <ReleaseDate />,
          },
          {
            path: "min-stay",
            element: <MinStay />,
          },
          {
            path: "stock",
            element: <Stock />,
          },
          {
            path: "stop-sale",
            element: <StopSale />,
          },
          {
            path: "promo",
            element: <Promo />,
            children: [
              {
                path: "list",
                element: <List />,
              },
              {
                path: "create",
                element: <CreatePromo />,
              },
            ],
          },
          {
            path: "prix-chambre",
            element: <PrixChambre />,
          },
        ],
      },
      {
        path: "langues",
        element: <Langues />,
      },
      {
        path: "users",
        element: <GestionUsers />,
      },
    ],
  },
]);
const service = new BaseService("SectionContenu");
function App() {
  const dispatch = useDispatch();
  useScript("vendor/bootstrap/js/bootstrap.bundle.min.js");
  useScript("vendor/glightbox/js/glightbox.min.js");
  useScript("vendor/isotope-layout/isotope.pkgd.min.js");
  useScript("vendor/swiper/swiper-bundle.min.js");
  useScript("vendor/php-email-form/validate.js");
  useScript("js/main.js");
  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if (!lng) localStorage.setItem("lng", "fr");
    lng = localStorage.getItem("lng");
    service.getAll().then((res) => dispatch(setLanguages(res)));
    if (lng === "ar") {
      const elements = document.querySelectorAll(
        "p, ul, h1,h2,h3,h4,h5,h6, small, strong, li, div"
      );
      elements.forEach((element) => {
        element.style.direction = "rtl";
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Check if the current protocol is not HTTPS
      if (window.location.protocol !== "https:" || window.location.hostname.includes("www")) {
        // Replace http with https in the URL
        const httpsURL = `https://${window.location.hostname.replace(/^www\./, '')}${window.location.pathname}${window.location.search}`;

        // Redirect to the updated URL
        window.location.replace(httpsURL);
      }
    }
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
