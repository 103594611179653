import { BsCalendar2CheckFill } from "react-icons/bs";
import AppText from "../components/app-text";
import HebergementService from "../service/hebergement.service";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchForm from "../search-form";

const service = new HebergementService();
export default function Chambres() {
  const [rows, setRows] = useState([]);
  const [lng, setLng] = useState(null);
  useEffect(() => {
    service.getAll().then((res) => setRows(res));
    setLng(localStorage.getItem("lng"));
  }, []);
  if (!lng) return <AppText cle={"chargement"} />;
  return (
    <main>
      <div id={"rooms-page-header"} style={{ padding: "180px 0" }}>
        <div className="container-fluid" style={{ position: "absolute" }}>
          <h2 style={{ color: "white", marginTop: 48, fontSize: "3.5rem" }}>
            <AppText cle={"Chambres"} />
          </h2>
        </div>

        <SearchForm />
      </div>

      <div className="container-fluid g-5">
        <div className="row mt-5 border-bottom pb-2">
          <div className="col-12 col-md-9">
            <p style={{ color: "darkgray", fontStyle: "italic" }}>
              <AppText cle={"chambres_st"} />
            </p>
          </div>
          <div className="col-12 col-md-3">
            <button
              style={{
                background: "rgb(214, 171, 95)",
                color: "white",
              }}
              className="app-button ms-auto p-4"
              onClick={() =>
                window.open(
                  "https://direct-book.com/properties/HotelRoyalASBUTunisDIRECT",
                  "_blank"
                )
              }
            >
              <BsCalendar2CheckFill style={{ marginRight: 8 }} />
              <AppText cle={"reservez_en_ligne"} />
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid g-0">
        <div className="rooms-container-sep-page">
          {rows.map((r) => (
            <div key={r.id} className="room-details-container">
              <div className="room-details">
                <h2>{r[`categorie${lng === "fr" ? "" : "_" + lng}`]}</h2>
                <small style={{ color: "var(--primary-color)" }}>
                  <AppText cle={"a_partir_de"} />
                </small>
                <h4>
                  <strong>{r.debut_prix}DT</strong>
                </h4>
                <p>{r[`description${lng === "fr" ? "" : "_" + lng}`]}</p>

                <div className="row text-center gy-4 my-5">
                  {r.room_accessories.map((ra) => (
                    <div className="col-4" key={ra.id}>
                      <img height={35} src={ra.image} alt="" />
                    </div>
                  ))}
                </div>
                <a
                  className="bouton-reserver-chambre"
                  href="https://direct-book.com/properties/HotelRoyalASBUTunisDIRECT"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsCalendar2CheckFill style={{ marginRight: 8 }} />
                  <AppText cle={"Je réserve"} />
                </a>
              </div>
              <div className="room-image">
                <img src={r.image_hebergements[0]?.image} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
