import { useCallback, useEffect, useState } from "react";
import { FaLanguage } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import AppText from "./components/app-text";
import BaseService from "./service/base.service";
import { BiCalendar } from "react-icons/bi";
const service = new BaseService("InformationHotel");

export default function Landing() {
  const [landingData, setLandingData] = useState();
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    service.getLandingData().then((res) => setLandingData(res));
  }, []);

  const toggleSearchForm = () => {
    return window.open('https://direct-book.com/properties/HotelRoyalASBUTunisDIRECT', '_blank')

    const searchForm = document.querySelector("#search-form");
    const searchFormClose = document.querySelector("#search-form-close");

    if (searchForm.classList.contains("show")) {
      searchForm.classList.remove("show");
      searchFormClose.classList.remove("show");
    } else {
      searchForm.classList.add("show");
      searchFormClose.classList.add("show");
    }
  };

  const handleHeaderLoad = useCallback(() => {
    let selectHeader = document.querySelector("#header");
    let selectTopbar = document.querySelector("#topbar");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");

          if (selectTopbar) {
            selectTopbar.classList.add("topbar-scrolled");
          }
        } else {
          selectHeader.classList.remove("header-scrolled");

          if (selectTopbar) {
            selectTopbar.classList.remove("topbar-scrolled");
          }
        }
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const searchForm = document.querySelector("#search-form");
        const searchFormButton = document.querySelector("#search-form-button");
        const searchFormClose = document.querySelector("#search-form-close");

        if (window.scrollY > viewportHeight - 200) {
          // searchForm.classList.add("header-scrolled");
          searchFormButton.classList.add("show");
        } else {
          if (searchForm) {
            searchForm.classList.remove("show");
          }
          if (searchFormClose) {
            searchFormClose.classList.remove("show");
          }
          if (searchFormButton) {
            searchFormButton.classList.remove("show");
          }
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }
  }, []);

  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };
  const toggleMobileNav = (event) => {
    if (window.innerWidth < 991) {
      document.querySelector("#navbar").classList.toggle("navbar-mobile");
      document.querySelector("#ham").classList.toggle("bi-list");
      document.querySelector("#ham").classList.toggle("bi-x");
    }
    // Prevent the default link behavior
    event.preventDefault();

    // Get the clicked link's parent element (li)
    const parentElement = event.target;
    // Remove the active class from the currently active link
    if (activeLink) {
      activeLink.classList.remove("active");
    }

    // Add the active class to the clicked link's parent element
    parentElement.classList.add("active");

    // Update the active link state variable
    setActiveLink(parentElement);
  };

  const changeLanguage = (value) => {
    localStorage.setItem("lng", value);
    window.location.reload();
  };

  if (!landingData)
    return (
      <p>
        <AppText cle={"chargement"} />
      </p>
    );
  return (
    <div>
      <section
        id="topbar"
        className={`d-flex align-items-center fixed-top topbar-transparent`}
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <div className="d-flex flex-wrap">
            <i className="bi bi-phone d-flex ms-md-4 align-items-center">
              <span>{landingData.informationsHotel.tel1}</span>
            </i>
            <i className="bi bi-envelope ms-md-4 d-flex align-items-center">
              <span>{landingData.informationsHotel.email1}</span>
            </i>
          </div>

          <div className="app-dropdown">
            <FaLanguage style={{ fontSize: 28, cursor: "pointer" }} />
            <ul
              className={`app-dropdown-menu ${
                localStorage.getItem("lng") === "ar" ? "ltr" : "rtl"
              }`}
            >
              <li onClick={() => changeLanguage("ar")}>
                العربية
                <img
                  src="assets/img/saudi-arabia.png"
                  alt=""
                  style={{ marginRight: 8 }}
                />
              </li>

              <li onClick={() => changeLanguage("en")}>
                English
                <img
                  src="assets/img/england.png"
                  alt=""
                  style={{ marginRight: 8 }}
                />
              </li>
              <li onClick={() => changeLanguage("fr")}>
                Français
                <img
                  src="assets/img/france.png"
                  alt=""
                  style={{ marginRight: 8 }}
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
      <header
        style={{ zIndex: 11 }}
        onLoad={handleHeaderLoad}
        id="header"
        className={`fixed-top d-flex align-items-center header-transparent `}
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <a href="/" onClick={toggleMobileNav}>
                <img src="assets/img/logo-Royal-ASBU.png" alt="" />
              </a>
            </h1>
          </div>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "home" ? "active" : ""
                  }`}
                  to="/"
                >
                  <AppText cle={"acceuil"} />
                </Link>
              </li>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "gallery" ? "active" : ""
                  }`}
                  to="/#gallery"
                >
                  <AppText cle={"Gallerie"} />
                </Link>
              </li>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "rooms" ? "active" : ""
                  }`}
                  to="/rooms"
                >
                  <AppText cle={"Chambres"} />
                </Link>
              </li>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "spa" ? "active" : ""
                  }`}
                  to="/spas"
                >
                  <AppText cle={"spa"} />
                </Link>
              </li>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "events" ? "active" : ""
                  }`}
                  to="/conferences"
                >
                  <AppText cle={"Evènements"} />
                </Link>
              </li>
              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "restaurants" ? "active" : ""
                  }`}
                  to="/gastros"
                >
                  <AppText cle={"Restaurants"} />
                </Link>
              </li>
              {/* Uncomment the code below to add more nav-link items */}
              {/* <li onClick={toggleMobileNav}>
        <Link className={`nav-link scrollto ${activeLink === 'parties' ? 'active' : ''}`} to="/parties">
          <AppText cle={"Fêtes"} />
        </Link>
      </li> */}

              <li onClick={toggleMobileNav}>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "contact" ? "active" : ""
                  }`}
                  to="/#contact"
                >
                  <AppText cle={"Contact"} />
                </Link>
              </li>
              <li id="search-form-button" onClick={toggleMobileNav}>
                <a href="#" onClick={toggleSearchForm}>
                  <AppText cle={"Je réserve"} />

                  <BiCalendar style={{ marginLeft: 8 }} />
                </a>
              </li>
            </ul>
            <i
              id="ham"
              className="bi bi-list mobile-nav-toggle"
              onClick={toggleMobileNav}
            />
          </nav>
        </div>
      </header>

      <Outlet />

      {/*    footer*/}
      <footer id="footer">
        <div className="container">
          <img height={90} src="assets/img/logo-Royal-ASBU.png" alt="" />
          <p>
            <AppText cle={"pied_page"} />
          </p>
          <div className="social-links">
            <a
              href={landingData.informationsHotel.facebook}
              target="_blank"
              rel="noreferrer"
              className="facebook"
            >
              <i className="bx bxl-facebook"></i>
            </a>
            <a
              href={landingData.informationsHotel.instagram}
              target="_blank"
              rel="noreferrer"
              className="instagram"
            >
              <i className="bx bxl-instagram"></i>
            </a>
          </div>
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>KREATEK.TN</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="https://kreatek.tn/" target="_blank" rel="noreferrer">
              KREATEK.TN
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
